<div class="user-role-assignment-container">
  <!-- Main assignment card -->
  <nb-card>
    <nb-card-header>
      <h5>User Role Assignment</h5>
    </nb-card-header>
    
    <nb-card-body>
      <nb-alert *ngIf="errorMessage" status="danger" closable>
        {{ errorMessage }}
      </nb-alert>
      
      <form [formGroup]="assignForm" (ngSubmit)="saveUserRoles()">
        <div class="form-group">
          <label for="userId" class="label">Select User</label>
          <nb-select 
            fullWidth 
            formControlName="userId" 
            placeholder="Select a user"
            (selectedChange)="onUserChange($event)">
            <nb-option value="">-- Select a user --</nb-option>
            <nb-option *ngFor="let user of users" [value]="user.id">
              {{ user.name || (user.firstName && user.lastName ? user.firstName + ' ' + user.lastName : user.username) }}
            </nb-option>
          </nb-select>
        </div>
        
        <div *ngIf="selectedUser" class="form-group">
          <nb-card>
            <nb-card-header>
              <h6>Available Roles</h6>
            </nb-card-header>
            <nb-card-body>
              <div *ngFor="let role of roles$ | async" class="role-item">
                <nb-checkbox 
                  [checked]="assignForm.get('roleIds')?.value?.includes(role.id)"
                  (checkedChange)="toggleRole($event, role.id)">
                  <div class="role-details">
                    <span class="role-name">{{ role.name }}</span>
                    <small class="role-description">{{ role.description }}</small>
                  </div>
                </nb-checkbox>
              </div>
            </nb-card-body>
          </nb-card>
        </div>
        
        <div class="form-actions">
          <button nbButton 
                  status="primary" 
                  type="submit" 
                  [disabled]="!selectedUser || loading">
            <nb-icon *ngIf="loading" icon="loader-outline" pack="eva" class="spin"></nb-icon>
            Save User Roles
          </button>
        </div>
      </form>
    </nb-card-body>
  </nb-card>
  
  <!-- User roles display card -->
  <nb-card *ngIf="selectedUser && userRoles.length">
    <nb-card-header>
      <h5>Assigned Roles for {{ selectedUser.name }}</h5>
    </nb-card-header>
    
    <nb-card-body>
      <table class="user-roles-table">
        <thead>
          <tr>
            <th>Role</th>
            <th>Description</th>
            <th>Permissions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let role of userRoles">
            <td>{{ role.name }}</td>
            <td>{{ role.description }}</td>
            <td>
              <nb-badge *ngFor="let perm of role.permissions" 
                        [text]="perm.name" 
                        status="primary"
                        class="permission-badge">
              </nb-badge>
            </td>
          </tr>
        </tbody>
      </table>
    </nb-card-body>
  </nb-card>
</div>