import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { catchError, from, map, Observable, of, switchMap } from 'rxjs';
import { BackendUserService } from './backend-auth.service';
import { RoleService } from './role.service';
import { Permission } from '../model/permission';
import { Role } from '../model/role';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private keycloak: KeycloakService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    private backendAuthService: BackendUserService,
    private roleService: RoleService
  ) { }

  async canActivate(): Promise<boolean> {
    if (isPlatformBrowser(this.platformId)) {
      const isLoggedIn = await this.keycloak.isLoggedIn();
      if (!isLoggedIn) {
        await this.keycloak.login(); // Redirects to Keycloak login page
        return false;
      }
      return true; // Allow access to authenticated users
    }
    return false; // Deny access on the server
  }

  /**
    * Check if the current user has the specified role
    */
  hasRole(role: string): Observable<boolean> {
    // Since isLoggedIn returns a boolean, we can use it directly
    if (!this.keycloak.isLoggedIn()) {
      return of(false);
    }

    // Get the current user ID from Keycloak
    const userId = this.keycloak.getKeycloakInstance().subject;

    // Check if userId is undefined or null
    if (!userId) {
      console.warn('No user ID found in Keycloak token');
      return of(false);
    }

    // Now userId is guaranteed to be a string
    return this.roleService.getUserRoles(userId).pipe(
      map(userRoleData => {
        // Check if the user has the required role
        return userRoleData.roles.some((userRole: any) => userRole.name === role);
      }),
      catchError(error => {
        console.error('Error fetching user roles:', error);
        return of(false);
      })
    );
  }

  /**
   * Check if the current user has any of the specified roles
   */
  // In your AuthGuard
  hasAnyRole(roles: string[]): Observable<boolean> {
    // If no roles specified, allow access
    if (!roles || roles.length === 0) {
      return of(true);
    }

    // First verify the user is logged in
    if (!this.keycloak.isLoggedIn()) {
      return of(false);
    }

    // Safely get the user ID
    const keycloakInstance = this.keycloak.getKeycloakInstance();
    if (!keycloakInstance || !keycloakInstance.subject) {
      console.warn('No user ID found in Keycloak token');
      return of(false);
    }

    const userId = keycloakInstance.subject;

    // Now fetch roles
    return this.roleService.getUserRoles(userId).pipe(
      map(userRoleData => {
        if (!userRoleData || !userRoleData.roles) {
          return false;
        }
        return roles.some(role =>
          userRoleData.roles.some((userRole: any) => userRole.name === role)
        );
      }),
      catchError(error => {
        console.error('Error fetching user roles:', error);
        return of(false);
      })
    );
  }

  /**
   * Check if the user has all of the specified roles
   */
  hasAllRoles(roles: string[]): Observable<boolean> {
    // If no roles specified, allow access
    if (!roles || roles.length === 0) {
      return of(true);
    }

    // Since isLoggedIn returns a boolean, we can use it directly
    if (!this.keycloak.isLoggedIn()) {
      return of(false);
    }

    // Get the current user ID from Keycloak
    const userId = this.keycloak.getKeycloakInstance().subject;

    // Check if userId is undefined or null
    if (!userId) {
      console.warn('No user ID found in Keycloak token');
      return of(false);
    }

    return this.roleService.getUserRoles(userId).pipe(
      map(userRoleData => {
        // Check if the user has all of the required roles
        return roles.every(role =>
          userRoleData.roles.some((userRole: any) => userRole.name === role)
        );
      }),
      catchError(error => {
        console.error('Error fetching user roles:', error);
        return of(false);
      })
    );
  }

  hasAnyPermission(permissionIds: string[]): Observable<boolean> {
  console.log(`hasAnyPermission called with: ${JSON.stringify(permissionIds)}`);
  
  // If no permissions specified, allow access
  if (!permissionIds || permissionIds.length === 0) {
    console.log("No permissions required, returning true");
    return of(true);
  }

  // First verify the user is logged in
  if (!this.keycloak.isLoggedIn()) {
    console.log("User not logged in, returning false");
    return of(false);
  }

  // Safely get the user ID
  const keycloakInstance = this.keycloak.getKeycloakInstance();
  if (!keycloakInstance || !keycloakInstance.subject) {
    console.warn('No user ID found in Keycloak token');
    return of(false);
  }

  const userId = keycloakInstance.subject;
  console.log(`Checking permissions for user: ${userId}`);

  // Use the existing getUserRoles method
  return this.roleService.getUserRoles(userId).pipe(
    map(userRoleData => {
      console.log(`Received user role data:`, userRoleData);
      
      if (!userRoleData || !userRoleData.roles) {
        console.log("No roles found for user");
        return false;
      }

      // Extract all permissions from all roles
      const userPermissions: Permission[] = [];
      userRoleData.roles.forEach((role: Role) => {
        console.log(`Processing role: ${role.name}`);
        if (role.permissions && role.permissions.length > 0) {
          userPermissions.push(...role.permissions);
        }
      });

      console.log(`User has ${userPermissions.length} permissions`);
      console.log(`Required permissions: ${JSON.stringify(permissionIds)}`);
      
      const result = permissionIds.some((permissionId: string) =>
        userPermissions.some((permission: Permission) => permission.name === permissionId)
      );
      
      console.log(`Permission check result: ${result}`);
      return result;
    }),
    catchError(error => {
      console.error('Error fetching user roles and permissions:', error);
      return of(false);
    })
  );
}
}
