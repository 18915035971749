import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NbButtonModule, NbCardModule, NbIconModule } from '@nebular/theme';
import { TableModule } from 'primeng/table';
import { SurveyService } from '../../services/survey/survey.services';
import { Product } from '../../model/product';

@Component({
  selector: 'app-surveys',
  standalone: true,
  imports: [
    NbCardModule,
    NbButtonModule,
    NbIconModule,
    RouterModule,
    TableModule,
  ],
  templateUrl: './surveys.component.html',
  styleUrl: './surveys.component.scss',
  providers: [SurveyService],
})
export class SurveysComponent implements OnInit {
  products!: Product[];

  constructor(private surveyService: SurveyService) {}

  ngOnInit(): void {
    this.surveyService.getProductsMini().then((data) => {
      this.products = data;
    });
  }
}
