import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  NbAlertModule,
  NbBadgeModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbFormFieldModule,
  NbIconModule,
  NbInputModule,
  NbListModule,
  NbSelectModule,
  NbTableModule,
  NbToggleModule,
  NbTreeGridModule
} from '@nebular/theme';
import { Observable } from 'rxjs';
import { DatabaseUser } from '../../model/database-user';
import { Role } from '../../model/role';
import { RoleService } from '../../auth/role.service';
import { BackendUserService } from '../../auth/backend-auth.service';
import { User } from '../../model/user';




@Component({
  selector: 'app-user-role-assignment',
  templateUrl: './user-role-assignment.component.html',
  styleUrls: ['./user-role-assignment.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NbCardModule,
    NbButtonModule,
    NbIconModule,
    NbInputModule,
    NbCheckboxModule,
    NbListModule,
    NbSelectModule,
    NbFormFieldModule,
    NbToggleModule,
    NbBadgeModule,
    NbAlertModule,
    NbTableModule,
    NbTreeGridModule
  ]
})
export class UserRoleAssignmentComponent implements OnInit {
  users: DatabaseUser[] = [];
  roles$: Observable<Role[]>;

  selectedUser: User | null = null;
  userRoles: Role[] = [];

  assignForm: FormGroup;
  loading = false;
  errorMessage: string | null = null;

  constructor(
    private roleService: RoleService,
    private userService: BackendUserService,
    private fb: FormBuilder
  ) {
    this.roles$ = this.roleService.getRoles();

    this.assignForm = this.fb.group({
      userId: ['', Validators.required],
      roleIds: [[] as string[]]  // Removed required validator since empty roles should be allowed
    });

    // Debug form state changes
    this.assignForm.valueChanges.subscribe(value => {
      console.log('Form value changed:', value);
      console.log('Form valid:', this.assignForm.valid);
      console.log('userId valid:', this.assignForm.get('userId')?.valid);
      console.log('roleIds:', this.assignForm.get('roleIds')?.value);
    });
  }

  ngOnInit(): void {
    this.loadUsers();
  }

  loadUsers(): void {
    this.loading = true;
    this.errorMessage = null;

    this.userService.getUsers().subscribe({
      next: (users) => {
        this.users = users;
        this.loading = false;
      },
      error: (err) => {
        this.errorMessage = 'Failed to load users: ' + (err.message || 'Unknown error');
        this.loading = false;
        console.error('User fetch error', err);
      }
    });
  }

  onUserChange(event: any): void {
    const userId = event;
    console.log('User selected:', userId);

    if (!userId) {
      this.userRoles = [];
      this.selectedUser = null;
      return;
    }

    // Explicitly update the form control
    this.assignForm.get('userId')?.setValue(userId, { emitEvent: false });
    console.log('userId value after set:', this.assignForm.get('userId')?.value);

    // Find and set the selected user
    const user = this.users.find(u => u.id === userId);
    if (user) {
      let displayName = user.name;
      if (!displayName && user.firstName && user.lastName) {
        displayName = `${user.firstName} ${user.lastName}`;
      } else if (!displayName) {
        displayName = user.username;
      }

      this.selectedUser = {
        id: userId,
        name: displayName
      };

      this.loadUserRoles(userId);
    }
  }

  loadUserRoles(userId: string): void {
    this.loading = true;
    this.roleService.getUserRoles(userId).subscribe({
      next: (userRole) => {
        this.userRoles = userRole.roles;

        // Set roleIds in the form
        const roleIds = userRole.roles.map(r => r.id);
        console.log('Setting roleIds in form:', roleIds);
        this.assignForm.get('roleIds')?.setValue(roleIds);

        this.loading = false;
      },
      error: (err) => {
        this.errorMessage = 'Failed to load user roles: ' + (err.message || 'Unknown error');
        this.loading = false;
        console.error('User roles fetch error', err);
      }
    });
  }

  toggleRole(event: any, roleId: string): void {
    console.log('Toggle role:', roleId, 'Checked:', event);
    const roleIdsControl = this.assignForm.get('roleIds');
    const currentRoleIds = [...roleIdsControl?.value || []];

    if (event) {
      // Add the role if checked
      roleIdsControl?.setValue([...currentRoleIds, roleId]);
    } else {
      // Remove the role if unchecked
      roleIdsControl?.setValue(currentRoleIds.filter((id: string) => id !== roleId));
    }

    console.log('roleIds after toggle:', roleIdsControl?.value);
  }

  saveUserRoles(): void {
    console.log('Save button clicked');
    console.log('Form state:', this.assignForm.value, 'Valid:', this.assignForm.valid);

    if (!this.selectedUser) {
      this.errorMessage = 'No user selected';
      return;
    }

    const userId = this.selectedUser.id;
    const roleIds = this.assignForm.get('roleIds')?.value || [];

    console.log('Saving roles for user:', userId, 'Roles:', roleIds);

    this.loading = true;
    this.roleService.assignRolesToUser(userId, roleIds).subscribe({
      next: (userRole) => {
        this.userRoles = userRole.roles;
        this.loading = false;
        this.errorMessage = null;
      },
      error: (err) => {
        this.errorMessage = 'Failed to update user roles: ' + (err.message || 'Unknown error');
        this.loading = false;
        console.error('User role update error', err);
      }
    });
  }
}