<div class="role-management-container">
  <div class="role-management-layout">
    <!-- Role List Section -->
    <nb-card class="role-list-card">
      <nb-card-header class="d-flex justify-content-between align-items-center">
        <h5>Roles</h5>
        <button nbButton status="primary" size="small" (click)="createNewRole()">
          <nb-icon icon="plus-outline"></nb-icon> New Role
        </button>
      </nb-card-header>
      <nb-card-body>
        <nb-list>
          <nb-list-item *ngFor="let role of roles$ | async" 
                        [class.selected-role]="selectedRole?.id === role.id"
                        (click)="editRole(role)">
            <div class="role-list-item">
              <span class="role-name">{{ role.name }}</span>
              <small class="role-info">{{ role.permissions.length }} permissions</small>
            </div>
          </nb-list-item>
        </nb-list>
      </nb-card-body>
    </nb-card>
    
    <!-- Role Edit/Create Section -->
    <nb-card class="role-edit-card">
      <nb-card-header>
        <h5>{{ formMode === 'create' ? 'Create New Role' : 'Edit Role' }}</h5>
      </nb-card-header>
      <nb-card-body>
        <form [formGroup]="roleForm" (ngSubmit)="saveRole()">
          <div class="form-group">
            <label for="name" class="label">Role Name</label>
            <input nbInput fullWidth id="name" formControlName="name" placeholder="Enter role name">
            <nb-alert *ngIf="roleForm.get('name')?.invalid && roleForm.get('name')?.touched" 
                     status="danger" size="tiny" class="mt-1">
              Role name is required
            </nb-alert>
          </div>
          
          <div class="form-group">
            <label for="description" class="label">Description</label>
            <textarea nbInput fullWidth id="description" formControlName="description" 
                      placeholder="Enter description" rows="3"></textarea>
          </div>
          
          <div class="form-group">
            <label class="label">Permissions</label>
            
            <div *ngFor="let module of permissionsByModule | keyvalue" class="permission-module">
              <nb-card accent="primary" class="mb-3">
                <nb-card-header class="module-header">
                  {{ module.key }}
                </nb-card-header>
                <nb-card-body>
                  <div *ngFor="let permission of module.value" class="permission-item">
                    <nb-checkbox 
                      [checked]="roleForm.get('permissions')?.value.includes(permission.id)"
                      (checkedChange)="togglePermission($event, permission.id)">
                      <div class="permission-details">
                        <span class="permission-name">{{ permission.name }}</span>
                        <small class="permission-description">{{ permission.description }}</small>
                      </div>
                    </nb-checkbox>
                  </div>
                </nb-card-body>
              </nb-card>
            </div>
          </div>
          
          <div class="form-actions">
            <button nbButton status="primary" type="submit" [disabled]="roleForm.invalid">
              {{ formMode === 'create' ? 'Create Role' : 'Update Role' }}
            </button>
            <button nbButton status="basic" type="button" class="ml-2" 
                    *ngIf="formMode === 'edit'" (click)="createNewRole()">
              Cancel
            </button>
            <button nbButton status="danger" type="button" class="ml-2" 
                    *ngIf="formMode === 'edit' && selectedRole" 
                    (click)="deleteRole(selectedRole!)">
              Delete
            </button>
          </div>
        </form>
      </nb-card-body>
    </nb-card>
  </div>
</div>