import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NbMenuModule } from '@nebular/theme';
import { LayoutComponent } from './components/layout/layout.component';
import { MENU_ITEMS } from './menu-items';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { KeycloakService } from 'keycloak-angular';
import { AuthGuard } from './auth/auth.guard';
import { Subscription } from 'rxjs';
import { KeycloakEventType } from 'keycloak-angular'; 
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [LayoutComponent, NbMenuModule, NbEvaIconsModule, RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  providers: [],
})
export class AppComponent implements OnInit {
  menu = MENU_ITEMS;
  loggedIn: Boolean = false;
  private subscription = new Subscription();
  //constructor(private keycloakService: KeycloakService) {}

  constructor(
    private keycloakService: KeycloakService,
    private authGuardService: AuthGuard
  ) {}

  ngOnInit(): void {
    // Check initial login status
    this.loggedIn = this.keycloakService.isLoggedIn();
    
    // If logged in, apply role-based menu filtering
    if (this.loggedIn) {
      this.updateMenuVisibility();
    }
    
    // Listen for Keycloak events to update login status and menu
    if (this.keycloakService.keycloakEvents$) {
      this.subscription.add(
        this.keycloakService.keycloakEvents$.subscribe(event => {
          if (event.type === KeycloakEventType.OnAuthSuccess) {
            this.loggedIn = true;
            this.updateMenuVisibility();
          } else if (event.type === KeycloakEventType.OnAuthLogout) {
            this.loggedIn = false;
          } else if (event.type === KeycloakEventType.OnTokenExpired && this.loggedIn) {
            // Refresh token and update menu if needed
            this.updateMenuVisibility();
          }
        })
      );
    }
  }

  updateMenuVisibility(): void {
    let pendingChecks = 0;

    const processItems = (items: any[]) => {
      items.forEach(item => {
        // Check if this item requires a specific role
        if (item.requirePermissions) {
          pendingChecks++;
          this.authGuardService.hasAnyPermission(item.requirePermissions).subscribe({
            next: hasRole => {
              item.hidden = !hasRole;
              pendingChecks--;
              if (pendingChecks === 0) {
                // Force menu update if needed by your framework
                this.menu = [...this.menu];
              }
            },
            error: () => {
              item.hidden = true;
              pendingChecks--;
              if (pendingChecks === 0) {
                this.menu = [...this.menu];
              }
            }
          });
        }
        
        // Process children recursively
        if (item.children && item.children.length > 0) {
          processItems(item.children);
        }
      });
    };

    // Start processing
    processItems(this.menu);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
