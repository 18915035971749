import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, take } from 'rxjs';
import { Role } from '../model/role';
import { Permission } from '../model/permission';
import { RoleService } from '../auth/role.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { 
  NbCardModule, 
  NbButtonModule, 
  NbIconModule, 
  NbInputModule, 
  NbCheckboxModule,
  NbListModule,
  NbSelectModule,
  NbFormFieldModule,
  NbToggleModule,
  NbAlertModule
} from '@nebular/theme';

@Component({
  selector: 'app-role-management',
  templateUrl: './role-management.component.html',
  styleUrls: ['./role-management.component.scss'],
  standalone: true,
  imports: [
    CommonModule, 
    ReactiveFormsModule, 
    FormsModule, 
    NbCardModule, 
    NbButtonModule, 
    NbIconModule, 
    NbInputModule,
    NbCheckboxModule,
    NbListModule,
    NbSelectModule,
    NbFormFieldModule,
    NbToggleModule,
    NbAlertModule
  ]
})
export class RoleManagementComponent implements OnInit {
  roles$: Observable<Role[]>;
  permissions$: Observable<Permission[]>;

  roleForm: FormGroup;
  selectedRole: Role | null = null;

  formMode: 'create' | 'edit' = 'create';
  permissionsByModule: Map<string, Permission[]> = new Map();

  constructor(
    private roleService: RoleService,
    private fb: FormBuilder
  ) {
    this.roles$ = this.roleService.getRoles();
    this.permissions$ = this.roleService.getPermissions();

    this.roleForm = this.fb.group({
      name: ['', Validators.required],
      description: [''],
      permissions: [[] as string[]]
    });
  }

  ngOnInit(): void {
    this.roles$ = this.roleService.getRoles();
    this.permissions$ = this.roleService.getPermissions();

    // Group permissions by module
    this.permissions$.subscribe(permissions => {
      this.permissionsByModule.clear();
      permissions.forEach(permission => {
        if (!this.permissionsByModule.has(permission.module)) {
          this.permissionsByModule.set(permission.module, []);
        }
        this.permissionsByModule.get(permission.module)?.push(permission);
      });
    });
  }

  createNewRole(): void {
    this.formMode = 'create';
    this.selectedRole = null;
    this.roleForm.reset({
      name: '',
      description: '',
      permissions: []
    });
  }

  editRole(role: Role): void {
    this.formMode = 'edit';
    this.selectedRole = role;
    this.roleForm.setValue({
      name: role.name,
      description: role.description,
      permissions: role.permissions.map(p => p.id)
    });
  }

  togglePermission(event: any, permissionId: string): void {
    console.log('Toggle permission:', permissionId, 'Checked:', event);
    const permissionsControl = this.roleForm.get('permissions');
    const currentPermissions = permissionsControl?.value || [];
    console.log('Current permissions before toggle:', currentPermissions);
    
    if (event) {
      // Add the permission if checked
      const updatedPermissions = [...currentPermissions, permissionId];
      console.log('Adding permission, new value:', updatedPermissions);
      permissionsControl?.setValue(updatedPermissions);
    } else {
      // Remove the permission if unchecked
      const updatedPermissions = currentPermissions.filter((id: string) => id !== permissionId);
      console.log('Removing permission, new value:', updatedPermissions);
      permissionsControl?.setValue(updatedPermissions);
    }
    
    console.log('Permissions after toggle:', permissionsControl?.value);
  }
  
  saveRole(): void {
    if (this.roleForm.invalid) return;
  
    const formValue = this.roleForm.value;
    const permissionIds: string[] = formValue.permissions || [];
  
    // Get full permission objects - using first() operator to auto-complete
    this.permissions$.pipe(
      take(1) // Take only the first emission and then complete
    ).subscribe(allPermissions => {
      const selectedPermissions = allPermissions.filter(p =>
        permissionIds.includes(p.id)
      );
  
      console.log('Selected permissions:', selectedPermissions);
  
      if (this.formMode === 'create') {
        const newRole: Omit<Role, 'id'> = {
          name: formValue.name,
          description: formValue.description,
          permissions: selectedPermissions
        };
  
        console.log('Creating new role with permissions:', newRole);
        this.roleService.createRole(newRole as Role).subscribe(() => {
          this.createNewRole();
        });
      } else if (this.formMode === 'edit' && this.selectedRole) {
        const updatedRole: Role = {
          ...this.selectedRole,
          name: formValue.name,
          description: formValue.description,
          permissions: selectedPermissions
        };
  
        console.log('Updating role with permissions:', updatedRole);
        this.roleService.updateRole(updatedRole).subscribe(() => {
          this.createNewRole();
        });
      }
    });
  }


  deleteRole(role: Role | null): void {
    if (!role) return;
    if (confirm(`Are you sure you want to delete the role "${role.name}"?`)) {
      this.roleService.deleteRole(role.id).subscribe();
    }
  }
}