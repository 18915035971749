import { Routes } from '@angular/router';
import { SurveysComponent } from './pages/surveys/surveys.component';
import { SurveyCreatorComponent } from './pages/surveys/survey-creator/survey-creator.component';
import { HomeComponent } from './pages/home/home.component';
import { AuthGuard } from './auth/auth.guard';
import { UserManagementComponent } from './pages/user-management/user-management.component';
import { RoleManagementComponent } from './role-management/role-management.component';
import { RoleGuard } from './auth/role.guard';
import { UserRoleAssignmentComponent } from './pages/user-role-assignment/user-role-assignment.component';

export const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [RoleGuard],
  },
  {
    path: 'users',
    component: UserManagementComponent,
    canActivate: [RoleGuard],
    data: { permissions: ['admin-manager', 'user-manager']},
  },
  {
    path: 'roles',
    component: RoleManagementComponent,
    canActivate: [RoleGuard],
    data: { permissions: ['admin-manager', 'role-manager']},
  },
  {
    path: 'user-roles',
    component: UserRoleAssignmentComponent,
    canActivate: [RoleGuard],
    data: { permissions: ['admin-manager', 'role-manager']},
  },
  {
    path: 'surveys',
    component: SurveysComponent,
    canActivate: [RoleGuard],
    data: { permissions: ['admin-manager', 'survey-manager']},
  },
  {
    path: 'surveys/create-survey',
    component: SurveyCreatorComponent,
    canActivate: [RoleGuard],
    data: { permissions: ['admin-manager', 'survey-manager']},
  },
  {
    path: '**',
    redirectTo: '', // Redirect unknown routes to home (optional)
  },
];
