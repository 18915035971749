<nb-card>
  <nb-card-header>
    User Management
  </nb-card-header>

  <nb-card-body>
    <div class="search-section">
      <div class="search-inputs">
        <input nbInput type="text" [(ngModel)]="searchParams.username" placeholder="Search by Username" (keyup.enter)="searchUsers()" class="search-input" />
        <input nbInput type="text" [(ngModel)]="searchParams.email" placeholder="Search by Email" (keyup.enter)="searchUsers()" class="search-input" />
      </div>

      <div class="search-actions">
        <button nbButton status="primary" (click)="searchUsers()" class="search-button">
          <nb-icon icon="search"></nb-icon> Search
        </button>
        <button nbButton status="warning" (click)="resetSearch()" class="search-button">
          <nb-icon icon="refresh"></nb-icon> Reset
        </button>
      </div>
    </div>

    <nb-alert *ngIf="errorMessage" status="danger" closable>
      {{ errorMessage }}
    </nb-alert>

    <nb-card *ngIf="editMode" class="edit-user-card">
      <nb-card-header>Edit User</nb-card-header>
      <nb-card-body>
        <div class="form-group">
          <label>Username: <input nbInput type="text" [(ngModel)]="selectedUser!.username" /></label>
        </div>
        <div class="form-group">
          <label>Email: <input nbInput type="text" [(ngModel)]="selectedUser!.email" /></label>
        </div>
        <div class="form-group">
          <label>First Name: <input nbInput type="text" [(ngModel)]="selectedUser!.firstName" /></label>
        </div>
        <div class="form-group">
          <label>Last Name: <input nbInput type="text" [(ngModel)]="selectedUser!.lastName" /></label>
        </div>
        <div class="form-group">
          <label>Roles:</label>
          <nb-select placeholder="Select Roles" multiple [(ngModel)]="selectedRoles" fullWidth>
            <nb-option *ngFor="let role of availableRoles" [value]="role">{{ role.name }}</nb-option>
          </nb-select>
        </div>
      </nb-card-body>
      <nb-card-footer>
        <button nbButton status="success" (click)="saveUser()" [disabled]="loading" class="edit-button">
          <nb-icon *ngIf="loading" icon="refresh-outline" class="spin"></nb-icon>
          Save
        </button>
        <button nbButton status="danger" (click)="cancelEdit()" [disabled]="loading" class="edit-button">Cancel</button>
      </nb-card-footer>
    </nb-card>

    <div *ngIf="!loading && !editMode" class="table-container">
      <p-table showGridlines stripedRows [value]="users" [tableStyle]="{ 'min-width': '50rem' }">
        <ng-template pTemplate="header">
          <tr>
            <th>Username</th>
            <th>Email</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-user>
          <tr>
            <td>{{ user.username }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.firstName || 'N/A' }}</td>
            <td>{{ user.lastName || 'N/A' }}</td>
            <td>
              <nb-icon [status]="user?.enabled ? 'success' : 'danger'" [icon]="user?.enabled ? 'checkmark-circle-outline' : 'close-circle-outline'"></nb-icon>
            </td>
            <td>
              <div class="btn-group">
                <button nbButton size="small" status="warning" (click)="editUser(user)" class="table-button">
                  <nb-icon icon="edit"></nb-icon> Edit
                </button>
                <button nbButton size="small" status="danger" (click)="deleteUser(user.id)" class="table-button">
                  <nb-icon icon="trash"></nb-icon> Delete
                </button>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>

    <div *ngIf="users.length === 0 && !loading" class="no-users-message">
      <nb-alert status="info">No users found.</nb-alert>
    </div>

    <div class="loading-indicator" *ngIf="loading">
      <nb-icon icon="refresh-outline" class="spin"></nb-icon> Loading...
    </div>

    <div class="pagination-container">
      <button nbButton status="info" (click)="previousPage()" [disabled]="searchParams.first === 0 || loading" class="pagination-button">
        <nb-icon icon="arrow-left"></nb-icon> Previous
      </button>
      <button nbButton status="info" (click)="nextPage()" [disabled]="loading" class="pagination-button">
        Next <nb-icon icon="arrow-right"></nb-icon>
      </button>
    </div>
  </nb-card-body>
</nb-card>
<nb-card>
  <nb-card-header>
    User Creation
  </nb-card-header>

  <nb-card-body>
    <div class="create-user-section">
      <button nbButton status="primary" (click)="openCreateUserModal()" [disabled]="loading">
        <nb-icon icon="plus"></nb-icon> Create User
      </button>
    </div>

    <nb-card *ngIf="createUserMode" class="create-user-card">
      <nb-card-header>Create User</nb-card-header>
      <nb-card-body>
        <div class="form-group">
          <label>Username: <input nbInput type="text" [(ngModel)]="newUser.username" required /></label>
        </div>
        <div class="form-group">
          <label>Email: <input nbInput type="text" [(ngModel)]="newUser.email" required /></label>
        </div>
        <div class="form-group">
          <label>First Name: <input nbInput type="text" [(ngModel)]="newUser.firstName" /></label>
        </div>
        <div class="form-group">
          <label>Last Name: <input nbInput type="text" [(ngModel)]="newUser.lastName" /></label>
        </div>
        <div class="form-group">
          <label>Roles:</label>
          <nb-select placeholder="Select Roles" multiple [(ngModel)]="selectedRoles" fullWidth>
            <nb-option *ngFor="let role of availableRoles" [value]="role">{{ role.name }}</nb-option>
          </nb-select>
        </div>
      </nb-card-body>
      <nb-card-footer>
        <button nbButton status="success" (click)="createUser()" [disabled]="loading || !newUser.username || !newUser.email">
          <nb-icon *ngIf="loading" icon="refresh-outline" class="spin"></nb-icon>
          Create
        </button>
        <button nbButton status="danger" (click)="cancelCreateUser()" [disabled]="loading">Cancel</button>
      </nb-card-footer>
    </nb-card>
  </nb-card-body>
</nb-card>