<nb-layout windowMode>
    <nb-layout-header fixed>
        <header></header>
    </nb-layout-header>

    <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive>
        <ng-content></ng-content>
    </nb-sidebar>
    <nb-layout-column>
        <ng-content select="router-outlet"></ng-content>
    </nb-layout-column>
</nb-layout>