import { Component } from '@angular/core';
import {
  NbActionsModule,
  NbContextMenuModule,
  NbIconModule,
  NbMenuModule,
  NbMenuService,
  NbSidebarService,
  NbUserModule,
} from '@nebular/theme';
import { KeycloakService } from 'keycloak-angular';
import { Subject, filter, map, takeUntil } from 'rxjs';

@Component({
  selector: 'header',
  standalone: true,
  imports: [NbIconModule, NbUserModule, NbActionsModule, NbContextMenuModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  providers: [],
})
export class HeaderComponent {
  private destroy$: Subject<void> = new Subject<void>();
  userMenu = [{ title: 'Profile' }, { title: 'Logout' }];
  userPictureOnly: boolean = false;
  user: any;

  constructor(
    private sidebarService: NbSidebarService,
    private nbMenuService: NbMenuService,
    private keycloakService: KeycloakService
  ) {}

  ngOnInit(): void {
    this.nbMenuService
      .onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'my-context-menu'),
        map(({ item: { title } }) => title)
      )
      .subscribe((title) => (title === 'Logout' ? this.logout() : ''));
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    return false;
  }

  async logout() {
    try {
      await this.keycloakService.logout(window.location.origin);
    } catch (error) {
      console.error('Logout error', error);
    }
  }
}
