<div class="header-container">
    <div class="logo-container">
        <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
            <nb-icon icon="menu-2-outline"></nb-icon>
        </a>
    </div>
</div>

<div class="header-container">
    <nb-actions size="medium">

        <nb-action class="control-item" icon="email-outline"></nb-action>
        <nb-action class="control-item" icon="bell-outline"></nb-action>
        <nb-action class="user-action">
            <nb-user [nbContextMenu]="userMenu" nbContextMenuTag="my-context-menu" [onlyPicture]="userPictureOnly"
                [name]="user?.name" [picture]="user?.picture">
            </nb-user>
        </nb-action>
    </nb-actions>
</div>