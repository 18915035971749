import { KeycloakService } from 'keycloak-angular';
import { environment } from '../../environments/environments';
import { isPlatformBrowser } from '@angular/common';
import keycloakConfig from './keycloak.config';

export function initializeKeycloak(
  keycloak: KeycloakService,
  platformId: Object
) {
  return async () => {
    if (isPlatformBrowser(platformId)) {
      await keycloak.init({
        config: keycloakConfig,
        initOptions: {
          onLoad: 'login-required', // Or 'check-sso' if login isn't required on first load
          silentCheckSsoRedirectUri: `${window.location.origin}/assets/silent-check-sso.html`,
          checkLoginIframe: false,
        },
        enableBearerInterceptor: true, // Add Bearer tokens to HTTP requests
      });
    }
  };
}
