// role.guard.ts
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthGuard } from './auth.guard';

// role.guard.ts
import { KeycloakService } from 'keycloak-angular';

@Injectable({
    providedIn: 'root'
})
export class RoleGuard implements CanActivate {
    constructor(
        private authGuard: AuthGuard, // Inject your existing AuthGuard
        private router: Router,
        private keycloakService: KeycloakService
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        // First ensure the user is authenticated using your existing AuthGuard
        return this.authGuard.canActivate().then(authenticated => {
            if (!authenticated) {
                return false; // AuthGuard will handle the redirection to login
            }

            // User is authenticated, now check roles
            const requiredRoles = route.data['permissions'] as string[];

            if (!requiredRoles || requiredRoles.length === 0) {
                return true; // No role requirements, access granted
            }
            console.log("requiredRoles " + requiredRoles);
            // Convert the result to a Promise for consistent return type
            return new Promise<boolean>((resolve) => {
                this.authGuard.hasAnyPermission(requiredRoles).subscribe({
                    next: hasRole => {
                        if (!hasRole) {
                            this.router.navigate(['/unauthorized']);
                            resolve(false);
                        } else {
                            resolve(true);
                        }
                    },
                    error: () => {
                        this.router.navigate(['/unauthorized']);
                        resolve(false);
                    }
                });
            });
        });
    }
}