import { Component } from '@angular/core';
import { NbLayoutModule, NbSidebarModule } from '@nebular/theme';
import { HeaderComponent } from '../header/header.component';

@Component({
  selector: 'layout',
  standalone: true,
  imports: [NbLayoutModule, HeaderComponent, NbSidebarModule],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss',
})
export class LayoutComponent {}
