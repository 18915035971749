import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DatabaseUser } from '../model/database-user';



@Injectable({
  providedIn: 'root'
})
export class BackendUserService {
  private baseUrl = 'http://localhost:8081/seyver/api/public/v1';
  private usersUrl = `${this.baseUrl}/users`;

  private usersSubject = new BehaviorSubject<DatabaseUser[]>([]);
  users$ = this.usersSubject.asObservable();

  constructor(private http: HttpClient) {
    this.loadUsers();
  }

  private loadUsers(): void {
    this.http.get<DatabaseUser[]>(this.usersUrl)
      .subscribe({
        next: (users) => this.usersSubject.next(users),
        error: (error) => console.error('Error loading users:', error)
      });
  }

  /**
   * Get all users from database
   */
  getUsers(): Observable<DatabaseUser[]> {
    return this.users$;
  }

  /**
   * Refresh user list from the server
   */
  refreshUsers(): Observable<DatabaseUser[]> {
    return this.http.get<DatabaseUser[]>(this.usersUrl)
      .pipe(
        tap(users => this.usersSubject.next(users)),
        catchError(error => {
          console.error('Error refreshing users:', error);
          return throwError(() => new Error('Failed to refresh users: ' + (error.message || 'Unknown error')));
        })
      );
  }

  /**
   * Get user by ID
   */
  getUserById(userId: string): Observable<DatabaseUser> {
    return this.http.get<DatabaseUser>(`${this.usersUrl}/${userId}`)
      .pipe(
        catchError(error => {
          console.error('Error fetching user details:', error);
          return throwError(() => new Error('Failed to fetch user details: ' + (error.message || 'Unknown error')));
        })
      );
  }

  /**
   * Create a new user
   */
  createUser(user: Partial<DatabaseUser>): Observable<DatabaseUser> {
    return this.http.post<DatabaseUser>(this.usersUrl, user)
      .pipe(
        tap(() => this.loadUsers()),
        catchError(error => {
          console.error('Error creating user:', error);
          return throwError(() => new Error('Failed to create user: ' + (error.message || 'Unknown error')));
        })
      );
  }

  /**
   * Update an existing user
   */
  updateUser(userId: string, user: Partial<DatabaseUser>): Observable<DatabaseUser> {
    return this.http.put<DatabaseUser>(`${this.usersUrl}/${userId}`, user)
      .pipe(
        tap(() => this.loadUsers()),
        catchError(error => {
          console.error('Error updating user:', error);
          return throwError(() => new Error('Failed to update user: ' + (error.message || 'Unknown error')));
        })
      );
  }

  /**
   * Delete a user
   */
  deleteUser(userId: string): Observable<void> {
    return this.http.delete<void>(`${this.usersUrl}/${userId}`)
      .pipe(
        tap(() => this.loadUsers()),
        catchError(error => {
          console.error('Error deleting user:', error);
          return throwError(() => new Error('Failed to delete user: ' + (error.message || 'Unknown error')));
        })
      );
  }

  /**
   * Check if a user exists by username or email
   */
  checkUserExists(username: string, email: string): Observable<boolean> {
    return this.http.get<boolean>(
      `${this.usersUrl}/exists?username=${username}&email=${email}`,
      { withCredentials: true }
    ).pipe(
      catchError(error => {
        console.error('Error checking user existence:', error);
        return throwError(() => new Error('Failed to check user: ' + (error.message || 'Unknown error')));
      })
    );
  }
}