import {
  APP_INITIALIZER,
  ApplicationConfig,
  PLATFORM_ID,
  provideZoneChangeDetection,
} from '@angular/core';
import { provideRouter } from '@angular/router';

import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { NbMenuModule, NbSidebarModule, NbThemeModule } from '@nebular/theme';
import Aura from '@primeng/themes/aura';
import { KeycloakService } from 'keycloak-angular';
import { providePrimeNG } from 'primeng/config';
import { routes } from './app.routes';
import { initializeKeycloak } from './auth/keycloak.init';
import { SafeTokenInterceptor } from './auth/safe-token.interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withInterceptorsFromDi()), // Include interceptors if needed
    KeycloakService,
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      deps: [KeycloakService, PLATFORM_ID],
      multi: true,
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: DebugTokenInterceptor,
    //   multi: true,
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SafeTokenInterceptor,
      multi: true,
    },
    provideClientHydration(),
    ...(NbThemeModule.forRoot({ name: 'default' })?.providers ?? []),
    ...(NbSidebarModule.forRoot()?.providers ?? []),
    ...(NbMenuModule.forRoot()?.providers ?? []),
    provideAnimationsAsync(),
    providePrimeNG({
      theme: {
        preset: Aura,
      },
    }),
  ],
};
