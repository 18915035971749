import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { KeycloakUserManagementService, UserSearchParams } from '../../auth/keycloak.user.management.service';
import { NbToggleModule, NbSelectModule, NbOptionModule, NbCardModule, NbButtonModule, NbIconModule, NbInputModule, NbBadgeModule, NbAlertModule } from '@nebular/theme';
import { TableModule } from 'primeng/table';
import { Role } from '../../model/role';
import { RoleService } from '../../auth/role.service';
import { BackendUserService } from '../../auth/backend-auth.service';
import { KeycloakUser } from '../../model/keycloak-user';
import { DatabaseUser } from '../../model/database-user';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
  standalone: true,
  imports: [
    NbToggleModule,
    CommonModule,
    FormsModule,
    NbCardModule,
    NbSelectModule,
    NbOptionModule,
    NbButtonModule,
    NbIconModule,
    NbInputModule,
    NbBadgeModule,
    NbAlertModule,
    TableModule
  ]
})
export class UserManagementComponent implements OnInit {
  users: KeycloakUser[] = [];
  loading = false;
  errorMessage: string | null = null;
  editMode = false;
  selectedUser: KeycloakUser | null = null;
  createUserMode = false;
  availableRoles: Role[] = [];
  selectedRoles: Role[] = [];

  newUser: any = {
    username: '',
    email: '',
    firstName: '',
    lastName: '',
    enabled: true
  };

  searchParams: UserSearchParams = {
    max: 10,
    first: 0
  };

  constructor(
    private keycloakUserService: KeycloakUserManagementService,
    private backendUserService: BackendUserService,
    private roleService: RoleService
  ) { }

  ngOnInit(): void {
    this.searchUsers();
    this.loadRoles();
  }

  loadRoles(): void {
    // Load roles from the backend instead of Keycloak
    this.roleService.getRoles().subscribe({
      next: (roles) => {
        this.availableRoles = roles;
      },
      error: (err) => {
        console.error('Failed to fetch roles', err);
        this.errorMessage = 'Failed to load roles. Please try again.';
      }
    });
  }

  // searchUsers(): void {
  //   this.loading = true;
  //   this.errorMessage = null;

  //   this.keycloakUserService.getUsers(this.searchParams)
  //     .subscribe({
  //       next: (users) => {
  //         this.users = users;
  //         this.loading = false;
  //       },
  //       error: (err) => {
  //         this.errorMessage = 'Failed to fetch users. Please try again.';
  //         console.error('User fetch error', err);
  //         this.loading = false;
  //       }
  //     });
  // }
  // Replace the searchUsers method with this:
  searchUsers(): void {
    this.loading = true;
    this.errorMessage = null;

    // Use backend service instead of Keycloak service
    this.backendUserService.refreshUsers()
      .subscribe({
        next: (databaseUsers) => {
          this.users = databaseUsers.map(dbUser => this.mapDatabaseUserToKeycloakUser(dbUser));
          this.loading = false;
        },
        error: (err) => {
          this.errorMessage = 'Failed to fetch users. Please try again.';
          console.error('User fetch error', err);
          this.loading = false;
        }
      });
  }

  nextPage(): void {
    this.searchParams.first = (this.searchParams.first || 0) + (this.searchParams.max || 10);
    this.searchUsers();
  }

  previousPage(): void {
    this.searchParams.first = Math.max(0, (this.searchParams.first || 0) - (this.searchParams.max || 10));
    this.searchUsers();
  }

  resetSearch(): void {
    this.searchParams = {
      max: 10,
      first: 0
    };
    this.searchUsers();
  }

  editUser(user: KeycloakUser): void {
    this.selectedUser = { ...user }; // Clone user to avoid direct mutation
    this.editMode = true;
  }

  saveUser(): void {
    if (this.selectedUser) {
      console.log(this.selectedUser!.id);
      this.loading = true;
      this.keycloakUserService.updateUser(this.selectedUser.id, this.selectedUser)
        .subscribe({
          next: () => {
            // Now update the user in the backend as well
            console.log(this.selectedUser!.id);
            this.backendUserService.updateUser(this.selectedUser!.id, {
              id: this.selectedUser!.id,
              username: this.selectedUser!.username,
              name: this.selectedUser!.firstName + ' ' + this.selectedUser!.lastName,
              firstName: this.selectedUser!.firstName,
              lastName: this.selectedUser!.lastName,
              email: this.selectedUser!.email,
              roles: this.selectedRoles
            }).subscribe({
              next: () => {
                this.loading = false;
                this.editMode = false;
                this.selectedUser = null;
                this.searchUsers();
              },
              error: (err) => {
                this.loading = false;
                this.errorMessage = 'Updated in Keycloak but failed to update in backend.';
                console.error('Backend update error', err);
              }
            });
          },
          error: (err) => {
            this.loading = false;
            this.errorMessage = 'Failed to update user in Keycloak.';
            console.error('Keycloak update error', err);
          }
        });
    }
  }

  cancelEdit(): void {
    this.editMode = false;
    this.selectedUser = null;
  }

  deleteUser(userId: string): void {
    if (confirm('Are you sure you want to delete this user?')) {
      this.loading = true;
      this.keycloakUserService.deleteUser(userId)
        .subscribe({
          next: () => {
            // Also delete from backend
            this.backendUserService.deleteUser(userId).subscribe({
              next: () => {
                this.loading = false;
                this.searchUsers();
              },
              error: (err) => {
                this.loading = false;
                this.errorMessage = 'Deleted from Keycloak but failed to delete from backend.';
                console.error('Backend delete error', err);
                this.searchUsers(); // Refresh anyway to show Keycloak changes
              }
            });
          },
          error: (err) => {
            this.loading = false;
            this.errorMessage = 'Failed to delete user from Keycloak.';
            console.error('Keycloak delete error', err);
          }
        });
    }
  }

  openCreateUserModal(): void {
    this.createUserMode = true;
    this.selectedRoles = [];
  }

  createUser(): void {
    this.loading = true;
    this.errorMessage = null;

    // Create user in Keycloak first
    this.keycloakUserService.createUser({
      username: this.newUser.username,
      email: this.newUser.email,
      firstName: this.newUser.firstName,
      lastName: this.newUser.lastName,
      enabled: true
      //realmRoles: this.selectedRoles.map(role => role.name)
    }).subscribe({
      next: (keycloakUser) => {
        // Now create the same user in the backend
        console.log("after keycloak creation ");
        console.log(keycloakUser);
        this.backendUserService.createUser({
          id: keycloakUser?.id, // Use the ID from Keycloak if available
          username: this.newUser.username,
          name: this.newUser.firstName + ' ' + this.newUser.lastName,
          firstName: this.newUser.firstName,
          lastName: this.newUser.lastName,
          email: this.newUser.email
        }).subscribe({
          next: (backendUser) => {
            // Now assign roles to the backend user
            if (this.selectedRoles.length > 0) {
              const roleIds = this.selectedRoles.map(role => role.id);
              this.roleService.assignRolesToUser(backendUser.id, roleIds).subscribe({
                next: () => {
                  this.loading = false;
                  this.createUserMode = false;
                  this.resetNewUser();
                  this.searchUsers();
                },
                error: (err) => {
                  this.loading = false;
                  this.errorMessage = 'User created but failed to assign roles.';
                  console.error('Role assignment error', err);
                  this.searchUsers();
                }
              });
            } else {
              // No roles to assign
              this.loading = false;
              this.createUserMode = false;
              this.resetNewUser();
              this.searchUsers();
            }
          },
          error: (err) => {
            this.loading = false;
            this.errorMessage = 'Created in Keycloak but failed to create in backend.';
            console.error('Backend create error', err);
            this.searchUsers(); // Refresh to show at least the Keycloak changes
          }
        });
      },
      error: (err) => {
        this.loading = false;
        this.errorMessage = 'Failed to create user in Keycloak.';
        console.error('Create user error', err);
      }
    });
  }

  cancelCreateUser(): void {
    this.createUserMode = false;
    this.resetNewUser();
  }

  private resetNewUser(): void {
    this.newUser = {
      username: '',
      email: '',
      firstName: '',
      lastName: '',
      enabled: true
    };
    this.selectedRoles = [];
  }

  private mapDatabaseUserToKeycloakUser(dbUser: DatabaseUser): KeycloakUser {
    return {
      id: dbUser.id,
      username: dbUser.username,
      email: dbUser.email || '',
      firstName: dbUser.firstName || '',
      lastName: dbUser.lastName || '',
      enabled: true, // Assuming all database users are enabled
      emailVerified: true, // Default value
      realmRoles: dbUser.roles?.map(role => role.name) || []
    };
  }

}