// safe-token.interceptor.ts
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, from, of } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable()
export class SafeTokenInterceptor implements HttpInterceptor {
    constructor(private keycloak: KeycloakService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        //console.log('Intercepting request to:', request.url);

        // Skip authentication for certain URLs if needed
        if (request.url.includes('/auth/') || request.url.endsWith('/token')) {
            //console.log('Skipping token for auth URL');
            return next.handle(request);
        }

        // Check if user is logged in
        const isLoggedIn = this.keycloak.isLoggedIn();
        //console.log('Is user logged in?', isLoggedIn);

        if (!isLoggedIn) {
            //console.log('Not logged in, skipping token');
            return next.handle(request);
        }

        // Try to get the token safely
        return from(this.getSafeToken()).pipe(
            switchMap(token => {
                //console.log('Token retrieved:', token ? 'Yes (length: ' + token.length + ')' : 'No');

                if (token) {
                    //console.log('Adding token to request:', request.url);
                    const authReq = request.clone({
                        setHeaders: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                    return next.handle(authReq);
                } else {
                    console.warn('No token available for request:', request.url);
                    return next.handle(request);
                }
            }),
            catchError(error => {
                console.error('Error in token interceptor:', error);
                return next.handle(request);
            })
        );
    }

    private async getSafeToken(): Promise<string | null> {
        try {
            const instance = this.keycloak.getKeycloakInstance();
            if (instance && instance.token) {
                const token = instance.token;
                //console.log(`Token available (first 10 chars: ${token.substring(0, 10)}...)`);

                // Check token structure by decoding (for debugging only)
                try {
                    const tokenParts = token.split('.');
                    if (tokenParts.length === 3) {
                        const header = JSON.parse(atob(tokenParts[0]));
                        //console.log('Token header type:', header.typ);
                    } else {
                        console.warn('Token does not have expected JWT structure');
                    }
                } catch (e) {
                    console.warn('Could not decode token for inspection');
                }

                return token;
            }

            // If that fails, try the standard getToken method
            return await this.keycloak.getToken();
        } catch (error) {
            console.warn('Error getting token:', error);
            return null;
        }
    }
}