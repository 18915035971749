import { NbMenuItem } from '@nebular/theme';

export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'Users',
    icon: 'people-outline',
    requirePermissions: ['admin', 'user-manager', 'role-manager'],
    children: [
      {
        title: 'User management',
        link: 'users',
        requirePermissions: ['admin', 'user-manager']
      },
      {
        title: 'Roles',
        link: 'roles',
        requirePermissions: ['admin', 'role-manager']
       }
      // {
      //   title: 'User Role',
      //   link: 'user-roles',
      // }
    ]
  },
  {
    title: 'Surveys',
    link: 'surveys',
    icon: 'question-mark-circle-outline',
    requirePermissions: ['admin', 'survey-manager']
  },
];
