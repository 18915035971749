import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, from, map, Observable, switchMap } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakUser } from '../model/keycloak-user';



export interface UserSearchParams {
  username?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  enabled?: boolean;
  max?: number;
  first?: number;
}

export interface KeycloakRole {
  id: string;
  name: string;
  description?: string;
}


@Injectable({
  providedIn: 'root'
})
export class KeycloakUserManagementService {
  private baseUrl = 'http://localhost:8080/admin/realms/seyver-partners';

  constructor(
    private http: HttpClient,
    private keycloakService: KeycloakService
  ) {}

  getUsers(params: UserSearchParams): Observable<KeycloakUser[]> {
    return from(this.keycloakService.getToken()).pipe(
      switchMap(token => {
        const headers = new HttpHeaders({
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        });

        return this.http.get<KeycloakUser[]>(`${this.baseUrl}/users`, {
          headers: headers,
          params: {
            'max': params.max?.toString() || '10',
            'first': params.first?.toString() || '0',
            'username': params.username || ''
          }
        }).pipe(
          catchError(error => {
            console.error('Detailed user fetch error:', {
              status: error.status,
              message: error.message,
              headers: error.headers?.keys(),
              body: error.error
            });
            throw error;
          })
        );
      })
    );
  }

  createUser(user: Partial<KeycloakUser>): Observable<any> {
    return from(this.keycloakService.getToken()).pipe(
      switchMap(token => {
        const headers = new HttpHeaders({
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        });
  
        // First create the user
        return this.http.post(`${this.baseUrl}/users`, user, { headers }).pipe(
          // Then get the user by username
          switchMap(() => {
            return this.http.get<any>(`${this.baseUrl}/users?username=${user.username}`, { headers }).pipe(
              map((response: any) => {
                // Check if response is an array or has a specific property structure
                if (Array.isArray(response)) {
                  return response[0]; // If it's an array, take the first item
                } else if (response && response.hasOwnProperty('users')) {
                  // Some APIs return { users: [...] }
                  return response.users[0];
                } else {
                  // Might be a direct object
                  return response;
                }
              })
            );
          }),
          catchError(error => {
            console.error('User creation error:', error);
            throw error;
          })
        );
      })
    );
  }

  updateUser(userId: string, user: Partial<KeycloakUser>): Observable<any> {
    return from(this.keycloakService.getToken()).pipe(
      switchMap(token => {
        const headers = new HttpHeaders({
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        });

        return this.http.put(`${this.baseUrl}/users/${userId}`, user, { headers }).pipe(
          catchError(error => {
            console.error('User update error:', error);
            throw error;
          })
        );
      })
    );
  }

  deleteUser(userId: string): Observable<void> {
    return from(this.keycloakService.getToken()).pipe(
      switchMap(token => {
        const headers = new HttpHeaders({
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        });

        return this.http.delete<void>(`${this.baseUrl}/users/${userId}`, { headers }).pipe(
          catchError(error => {
            console.error('User deletion error:', error);
            throw error;
          })
        );
      })
    );
  }

  getRealmRoles(): Observable<KeycloakRole[]> {
    return from(this.keycloakService.getToken()).pipe(
      switchMap(token => {
        const headers = new HttpHeaders({
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        });

        return this.http.get<KeycloakRole[]>(`${this.baseUrl}/roles`, { headers }).pipe(
          catchError(error => {
            console.error('Get roles error:', error);
            throw error;
          })
        );
      })
    );
  }

}