<nb-card>
    <nb-card-header>
        My Surveys
    </nb-card-header>

    <nb-card-body>
        <div class="button-container">
            <button nbButton status="primary" [routerLink]="'create-survey'">
                <nb-icon icon="plus"></nb-icon>Create Survey
            </button>
        </div>

        <div class="card">
            <p-table showGridlines stripedRows [value]="products" [tableStyle]="{ 'min-width': '50rem' }">
                <ng-template #header>
                    <tr>
                        <th>Code</th>
                        <th>Name</th>
                        <th>Category</th>
                        <th>Quantity</th>
                    </tr>
                </ng-template>
                <ng-template #body let-product>
                    <tr>
                        <td>{{ product.code }}</td>
                        <td>{{ product.name }}</td>
                        <td>{{ product.category }}</td>
                        <td>{{ product.quantity }}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </nb-card-body>

</nb-card>