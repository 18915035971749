import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Role } from '../model/role';
import { Permission } from '../model/permission';
import { UserRole } from '../model/user.role';


@Injectable({
  providedIn: 'root'
})
export class RoleService {
  private apiUrl = 'http://localhost:8081/seyver/api/public/v1'; 
  private userApiUrl = 'http://localhost:8081/seyver/api/public/v1/users'; 
  

  private rolesSubject = new BehaviorSubject<Role[]>([]);
  private permissionsSubject = new BehaviorSubject<Permission[]>([]);
  
  roles$ = this.rolesSubject.asObservable();
  permissions$ = this.permissionsSubject.asObservable();

  constructor(private http: HttpClient) {
    this.loadRoles();
    this.loadPermissions();
  }

  private loadRoles(): void {
    this.http.get<Role[]>(`${this.apiUrl}/roles`)
      .subscribe(roles => this.rolesSubject.next(roles));
  }

  private loadPermissions(): void {
    this.http.get<Permission[]>(`${this.apiUrl}/permissions`)
      .subscribe(permissions => this.permissionsSubject.next(permissions));
  }

  getRoles(): Observable<Role[]> {
    return this.roles$;
  }

  getPermissions(): Observable<Permission[]> {
    return this.permissions$;
  }

  getRole(id: string): Observable<Role> {
    return this.http.get<Role>(`${this.apiUrl}/${id}`);
  }

  getUserRoles(userId: string): Observable<UserRole> {
    return this.http.get<UserRole>(`${this.userApiUrl}/${userId}/roles`);
  }

  createRole(role: Role): Observable<Role> {
    return this.http.post<Role>(`${this.apiUrl}/roles`, role).pipe(
      tap(() => this.loadRoles())
    );
  }

  updateRole(role: Role): Observable<Role> {
    return this.http.put<Role>(`${this.apiUrl}/roles/${role.id}`, role).pipe(
      tap(() => this.loadRoles())
    );
  }

  deleteRole(id: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/roles/${id}`).pipe(
      tap(() => this.loadRoles())
    );
  }

  assignRolesToUser(userId: string, roleIds: string[]): Observable<UserRole> {
    return this.http.post<UserRole>(`${this.userApiUrl}/${userId}/roles`, { roleIds });
  }

  // Check if a user has a specific permission
  hasPermission(userRoles: Role[], permissionId: string): boolean {
    return userRoles.some(role => 
      role.permissions.some(permission => permission.id === permissionId)
    );
  }
}
