import { Component, OnInit } from '@angular/core';

const creatorOptions = {
  showLogicTab: true,
  isAutoSave: true,
};

@Component({
  selector: 'app-survey-creator',
  standalone: true,
  imports: [],
  templateUrl: './survey-creator.component.html',
  styleUrl: './survey-creator.component.scss',
})
export class SurveyCreatorComponent implements OnInit {
  ngOnInit(): void {}
}
